<div>
    <div style="
            padding-left: 25px;
            display: flex;
            position: fixed;
            z-index: 5;
            width: 95%;
            height: 80px;
        "
         class="fixedDivStyle">
        <div style="width: 40%; padding-left: 40px; padding-top: 10px">
            <mat-form-field appearance="fill" class="filterstyleText">
                <input type="text"
                       style="padding-top: 8px"
                       matInput
                       (keyup)="applyFilter($any($event.target).value)"
                       placeholder="Filter by Subject or Agreement" />
            </mat-form-field>
        </div>

        <div style="width: 30%; padding-left: 100px">
            <mat-form-field appearance="fill" class="filterstyle">
                <mat-label>Please select a Quarter</mat-label>
                <mat-select name="quarterCode"
                            [formControl]="quarterCodeFormControl"
                            (selectionChange)="changeNotesList($event.value)">
                    <mat-option *ngFor="let quarterCodeval of quarterCode"
                                [value]="quarterCodeval.quarter">
                        {{ quarterCodeval.quarter }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <!--<div style="width: 100%;padding-left: 75px;" *ngIf="showQuarter" align="end">
        <button color="primary"
                (click)="copynote()"
                mat-raised-button class="buttonstyling">
            Copy Notes
        </button>
    </div>-->
        <div style="
            padding-top: 0;
            padding-left: 25px;
            display: flex;
            position: fixed;
            z-index: 10;
            width: 95%;
        ">
            Refresh Date: {{cacheRefreshDate | date : 'MM/dd/yyyy h:mm a'}}
        </div>
        <div style="width: 100%; margin-right: 6%" align="end">
            <button color="primary"
                    (click)="copynote()"
                    mat-raised-button
                    class="buttonstyling">
                Copy Notes
            </button>
            <button color="primary"
                    (click)="downloadNotes()"
                    mat-raised-button
                    class="buttonstyling">
                Download Notes
            </button>
            <button color="primary"
                    (click)="newnote()"
                    mat-raised-button
                    class="buttonstyling">
                New Note
            </button>
        </div>
    </div>
</div>
<div class="flex-container-grid">
    <div
        class="mat-elevation-z8"
        style="
            width: 90%;
            margin-top: 110px;
            display: inline-block;
            margin-bottom: 10px;
        ">
        <mat-table
            [dataSource]="dataSource"
            class="mat-elevation-z8"
            matSort
            matSortActive="updateDate"
            matSortDirection="desc"
            style="width: 100%">
            <ng-container matColumnDef="subject">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Subject
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.subject }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="noteText">
                <mat-header-cell *matHeaderCellDef mat-sort-header
                    >Note</mat-header-cell
                >
                <mat-cell *matCellDef="let element">
                    {{ element.noteText }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="updateDate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>
                    Date Modified
                </mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.updateDate | date : 'MM/dd/yyyy' }}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="active">
                <mat-header-cell *matHeaderCellDef>Active</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    <mat-checkbox
                        [value]="element.active"
                        [checked]="element.active"
                        [disabled]="true"></mat-checkbox>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="actionsColumn">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell
                    class="icon-padding"
                    *matCellDef="let element; let j = index">
                    <mat-icon
                        inline="true"
                        matTooltip="Click to Edit"
                        (click)="getNote(element)"
                        >edit</mat-icon
                    >
                    <mat-icon
                        inline="true"
                        matTooltip="Click to Delete"
                        (click)="deleteNote(element.id)"
                        >close</mat-icon
                    >
                </mat-cell>
            </ng-container>
            <mat-header-row
                *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </mat-table>
        <mat-paginator
            [pageSizeOptions]="[20, 40, 60, 100, 150, 250, 500, 750, 1000]"
            aria-label="Select page of notelist"></mat-paginator>
    </div>
</div>
