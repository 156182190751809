import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Manufacturer } from '../model/Manufacturer';
import { Report } from '../model/Report';
import { View } from '../model/View';
import { ReportColumnLegacyAlias } from '../model/ReportColumnLegacyAlias';

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    constructor(private client: HttpClient) {}

    loadManufacturerList(): Observable<Array<Manufacturer>> {
        return this.client.get<Array<Manufacturer>>('/api/manufacturers');
    }

    loadReportList(): Observable<Array<Report>> {
        return this.client.get<Array<Report>>('/api/reports');
    }

    loadViewByID(id: any): Observable<View> {
        return this.client.get<View>(`/api/views/${id}`);
    }

    loadReportByID(id: any): Observable<Report> {
        return this.client.get<Report>(`/api/reports/${id}`);
    }

    loadReportByScheduleID(id: any): Observable<Array<Report>> {
        return this.client.get<Array<Report>>(
            `/api/reports/reportschedules/${id}`
        );
    }

    SaveReport(report: Report): Observable<any> {
        return this.client.post<any>('/api/reports', report);
    }
    EditReport(report: Report): Observable<any> {
        return this.client.put<any>('/api/reports', report);
    }

    loadViewList(): Observable<Array<View>> {
        return this.client.get<Array<View>>('/api/views');
    }

    loadAllViewList(): Observable<Array<View>> {
        return this.client.get<Array<View>>('/api/getAllviews');
    }

    SaveView(view: View): Observable<any> {
        return this.client.post<any>('/api/views', view);
    }

    EditView(view: View): Observable<any> {
        return this.client.put<any>('/api/views', view);
    }

    ExportReport(reports: Array<string>) {
        let headers = new HttpHeaders();
        headers.set('Content-Type', 'application/json');
        return this.client.post('/api/reports/downloadReport', reports, {
            headers: headers,
            responseType: 'arraybuffer',
        });
    }

    loadReportColumnLegacyAlias(): Observable<Array<ReportColumnLegacyAlias>>{
        return this.client.get<Array<ReportColumnLegacyAlias>>('/api/reportcolumnlegacyalias');
    }
}
