import { Component, OnInit } from '@angular/core';
import { MatTab, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ScheduleService } from '../../../services/schedule.service';
import { Schedule } from '../../../model/Schedule';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Manufacturer } from '../../../model/Manufacturer';
import { Observable, throwError } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { Product } from '../../../model/Product';
import { ReportManagement } from '../../../model/ReportManagement';
import {
    MatDialog,
    MatDialogConfig,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { View } from '../../../model/View';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ReportService } from '../../../services/report.service';
import { Report } from '../../../model/Report';
import { ReportColumn } from '../../../model/ReportColumn';
import { ReportParameter } from '../../../model/ReportParameter';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { clientSession } from '../../../client-session';
import { ReportSchedule } from '../../../model/ReportSchedule';
import { EmailTemplate } from '../../../model/EmailTemplate';
import { EmailtemplateService } from '../../../services/emailtemplate.service';
import { ReportEmailTemplate } from '../../../model/ReportEmailTemplate';
import { EmailGroup } from '../../../model/EmailGroup';
import { EmailgroupService } from '../../../services/emailgroup.service';
import { ReportEmailGroup } from '../../../model/ReportEmailGroup';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ReportColumnLegacyAlias } from '../../../model/ReportColumnLegacyAlias';

interface GPO {
    value: string;
    viewValue: string;
}

interface SelectedColumns {
    index: number;
    viewValue: string;
}

interface ReportPeriod {
    value: string;
    viewValue: string;
}

interface ReportType {
    value: string;
    viewValue: string;
}

interface Operator {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-report-wizard',
    templateUrl: './report-wizard.component.html',
    styleUrls: ['./report-wizard.component.scss'],
})
export class ReportWizardComponent implements OnInit {
    emailGrouplist: Array<EmailGroup> = [];

    dataSource: MatTableDataSource<EmailGroup>;
    dataSourceSchedule: MatTableDataSource<Schedule>;
    displayedColumns = ['selectGroup', 'groupName', 'totalContacts'];
    displayedColumnsSchedule = ['select', 'dayOfWeek'];
    displayedColumnsReportParameters = [
        'columnName',
        'operator',
        'parameterValue',
        'active',
        'actionsColumn',
    ];

    selectedColumnsForSort: Array<SelectedColumns> = [];

    typesOfReportViewColumns: string[] = [];
    reportViewColumns: string[] = [];

    typesOfSelectedReportViewColumns: Array<ReportColumn> = [];
    dataSourceReportParameters: MatTableDataSource<ReportParameter>;
    reportParameterlist: Array<ReportParameter> = [];
    tmpobjReportParameter: ReportParameter = {} as ReportParameter;
    objReportColumn: ReportColumn = {} as ReportColumn;
    schedules: Array<Schedule> = [];
    emailTemplates: Array<EmailTemplate> = [];
    selectedschedules: Array<string> = [];
    selectedEmailgroup: Array<EmailGroup> = [];
    manufacturer: Array<Manufacturer> = [];
    product: Array<Product> = [];
    view: Array<View> = [];
    reportcolumnlegacyalias: Array<ReportColumnLegacyAlias> = [];
    //options: string[] = ['One', 'Two', 'Three'];
    inputDrugname: any;
    selectedView: View | undefined;
    selectedReport: Report = {} as Report;
    SaveSelectedReport: Report = {} as Report;

    saveSelectedRptSchedulelist: Array<ReportSchedule> = [];
    saveSelectedRptEmailGrouplist: Array<ReportEmailGroup> = [];
    saveSelectedRptScheduleObj: ReportSchedule = {} as ReportSchedule;
    saveSelectedRptEmailtemplateObj: ReportEmailTemplate =
        {} as ReportEmailTemplate;
    submitted: boolean = false;

    myControl = new FormControl('');
    selection = new SelectionModel<EmailGroup>(true, []);
    selectionSchedule = new SelectionModel<Schedule>(true, []);

    public formGroup: FormGroup;
    public manufacturerFormControl: FormControl;

    /******* changes *****/
    public productNameFormControl: FormControl;
    public reportperiodFormControl: FormControl;
    public reporttypeFormControl: FormControl;
    public gpoFormControl: FormControl;
    public viewFormControl: FormControl;
    public appendToNameFormControl: FormControl;
    // public rptViewColumnsFormControl;

    public columnselectionFormControl: FormControl;
    public operatorFormControl: FormControl;
    public emailtemplateFormControl: FormControl;
    public valueFormControl: FormControl;
    public reportNameFormControl: FormControl;

    products: Array<Product> | undefined = [];

    filteredProducts!: Observable<Product[]>;
    filteredManufacturer!: Observable<Manufacturer[]>;
    filteredOptions!: Observable<string[]>;
    lastProductFilter: string = '';
    lastManufacturerFilter: string = '';
    manufacturerFilter: Manufacturer = {
        manufacturerId: '',
        manufacturerName: '',
    };
    /******* changes *****/

    viewMode: boolean = false;
    isEdit: boolean = false;
    data: any;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    constructor(
        private service: ScheduleService,
        private http: HttpClient,
        private router: Router,
        private reportservice: ReportService,
        private emailTemplateservice: EmailtemplateService,
        private emailGroupservice: EmailgroupService,
        private _snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {
        var mode = this.router.getCurrentNavigation()?.extras.state;

        if (mode != null) {
            this.data = mode['viewMode'];
            this.viewMode = true;
            this.isEdit = true;
        }
        this.dataSource = new MatTableDataSource(this.emailGrouplist);
        this.dataSourceSchedule = new MatTableDataSource(this.schedules);
        this.dataSourceReportParameters = new MatTableDataSource(
            this.reportParameterlist
        );

        this.manufacturerFormControl = new FormControl('', [
            Validators.required,
        ]);
        this.productNameFormControl = new FormControl('', [
            Validators.required,
        ]);
        this.reporttypeFormControl = new FormControl('', [Validators.required]);
        this.reportperiodFormControl = new FormControl('', [
            Validators.required,
        ]);
        this.gpoFormControl = new FormControl('', [Validators.required]);
        this.viewFormControl = new FormControl('', [Validators.required]);
        this.appendToNameFormControl = new FormControl(
            '',
            Validators.pattern('[A-Za-z0-9 _ ( )-]*')
        );
        //this.appendToNameFormControl = new FormControl();
        this.columnselectionFormControl = new FormControl('');
        this.operatorFormControl = new FormControl('');
        this.emailtemplateFormControl = new FormControl('', [
            Validators.required,
        ]);

        this.valueFormControl = new FormControl('');
        this.reportNameFormControl = new FormControl('');

        this.formGroup = new FormGroup({
            manufacturer: this.manufacturerFormControl,
            drugname: this.productNameFormControl,
            reporttype: this.reporttypeFormControl,
            reportperiod: this.reportperiodFormControl,
            gpo: this.gpoFormControl,
            view: this.viewFormControl,
            appendToName: this.appendToNameFormControl,
            emailtemplate: this.emailtemplateFormControl,
        });
    }

    /******* changes *****/
    async ngOnInit(): Promise<void> {
        this.loadInitialDropdown();
        this.dataSource = new MatTableDataSource(this.emailGrouplist);
        this.dataSourceSchedule = new MatTableDataSource(this.schedules);
        await this.loadProductList();
        this.loadReportColumnLegacyAlias();

        this.filteredProducts = this.productNameFormControl.valueChanges.pipe(
            startWith<string | Product[]>(''),
            map(value =>
                typeof value === 'string' ? value : this.lastProductFilter
            ),
            map(filter => this.filterProducts(filter))
        );

        this.filteredManufacturer =
            this.manufacturerFormControl.valueChanges.pipe(
                startWith<string | Manufacturer[]>(''),
                map(value =>
                    typeof value === 'string'
                        ? value
                        : this.lastManufacturerFilter
                ),
                map(filter => this.filterManufacturer(filter))
            );

        this.filteredOptions =
            this.columnselectionFormControl.valueChanges.pipe(
                startWith(''),
                map(value => this.filterReportViewColumns(value || ''))
            );

        this.operatorFormControl.valueChanges.subscribe(value => {
            if (value == 'AND/OR') {
                this.columnselectionFormControl.setValue(null);
                this.columnselectionFormControl.disable();
            } else {
                this.columnselectionFormControl.enable();
            }
        });

        if (this.viewMode) {
            this.reportperiodFormControl.setValue(this.data.reportPeriod);
            this.reporttypeFormControl.setValue(this.data.reportType);
            this.gpoFormControl.setValue(this.data.gpo);
            this.viewFormControl.setValue(this.data.viewId);
            this.LoadViewByID(this.viewFormControl.value);
            this.LoadReportByID(this.data.id);
            this.appendToNameFormControl.setValue(this.data.appendToName);
            this.reportNameFormControl.setValue(this.data.reportName);
        }

        this.enableDisableControls();
    }
    //Searchable dropdown for column Names
    private filterReportViewColumns(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.reportViewColumns.filter(option =>
            option.toLowerCase().includes(filterValue)
        );
    }

    //Searchable dropdown for product
    filterProducts(filter: string): Product[] {
        if (this.products) {
            if (filter) {
                return this.products.filter(option => {
                    return (
                        option.productName
                            .toLowerCase()
                            .indexOf(filter.toLowerCase()) >= 0
                    );
                });
            } else {
                return this.products.slice();
            }
        }
        return [];
    }

    //Searchable dropdown for manufacturer
    filterManufacturer(filter: string): Manufacturer[] {
        if (this.manufacturer) {
            if (filter) {
                return this.manufacturer.filter(option => {
                    return (
                        option.manufacturerName
                            .toLowerCase()
                            .indexOf(filter.toLowerCase()) >= 0
                    );
                });
            } else {
                return this.manufacturer.slice();
            }
        }
        return [];
    }

    displayProduct(product: Product): string {
        return product.productName;
    }

    displayManufacturer(manufacturer: Manufacturer): string {
        return manufacturer.manufacturerName;
    }

    //Load all the dropdowns values during the page load
    loadInitialDropdown() {
        this.loadScheduleList();
        this.loadManufacturerList();

        this.loadViewList();
        this.loadEmailTemplateList();
        this.loadEmailgroupList();
    }

    loadViewList() {
        this.reportservice
            .loadViewList()
            .subscribe(result => (this.view = result));
    }

    loadEmailTemplateList() {
        this.emailTemplateservice
            .loadEmailtemplateList()
            .subscribe(result => (this.emailTemplates = result));
    }

    loadEmailgroupList() {
        this.emailGroupservice.loadEmailgroupList().subscribe(result => {
            this.emailGrouplist = result;

            for (var i = 0; i < this.emailGrouplist.length; i++) {
                this.selectedEmailgroup.push(this.emailGrouplist[i]);
                this.emailGrouplist[i].totalContacts =
                    this.emailGrouplist[i].emails.length;
            }

            this.dataSource = new MatTableDataSource(this.emailGrouplist);
        });
    }

    loadReportColumnLegacyAlias() {
        this.reportservice.loadReportColumnLegacyAlias().subscribe(result => (this.reportcolumnlegacyalias = result));
    }


    async loadProductList() {
        this.products = await this.http
            .get<Array<Product>>('/api/products')
            .toPromise();

        if (this.viewMode) {
            this.productNameFormControl.setValue({
                productName: this.data.productName,
                productId: this.data.productId,
            });
            this.productNameFormControl.disable();
        } else {
            this.productNameFormControl.enable();
        }
    }

    loadManufacturerList() {
        this.reportservice.loadManufacturerList().subscribe(result => {
            this.manufacturer = result;
            if (this.viewMode) {
                const toSelect = this.manufacturer?.find(
                    c => c.manufacturerName == this.data.manufacturerName
                );
                this.manufacturerFormControl.setValue(toSelect);
            }
        });
    }

    loadScheduleList() {
        this.service.loadScheduleList().subscribe(result => {
            this.schedules = result;

            for (var i = 0; i < this.schedules.length; i++) {
                if (this.schedules[i].period.toUpperCase() == 'WEEKLY') {
                    this.schedules[i].period = 'Once a Week - ';
                    switch (this.schedules[i].dayOfWeek) {
                        case 2:
                            this.schedules[i].dayOfWeek = 'Monday';
                            break;
                        case 3:
                            this.schedules[i].dayOfWeek = 'Tuesday';
                            break;
                        case 4:
                            this.schedules[i].dayOfWeek = 'Wednesday';
                            break;
                        case 5:
                            this.schedules[i].dayOfWeek = 'Thursday';
                            break;
                        case 6:
                            this.schedules[i].dayOfWeek = 'Friday';
                            break;
                        case 7:
                            this.schedules[i].dayOfWeek = 'Saturday';
                            break;
                        case 1:
                            this.schedules[i].dayOfWeek = 'Sunday';
                            break;
                        default:
                            this.schedules[i].dayOfWeek = '';
                    }
                } else if (this.schedules[i].period.toUpperCase() == 'YEARLY') {
                    this.schedules[i].period = 'Once a Year - Month ';

                    this.schedules[i].dayOfWeek =
                        this.schedules[i].month == null
                            ? this.schedules[i].day
                            : this.schedules[i].month +
                              ' Day ' +
                              this.schedules[i].day;
                } else if (
                    this.schedules[i].period.toUpperCase() == 'QUARTERLY'
                ) {
                    this.schedules[i].period = 'Once a Quarter - Month ';
                    this.schedules[i].dayOfWeek =
                        this.schedules[i].month +
                        ' Day ' +
                        this.schedules[i].day;
                } else if (
                    this.schedules[i].period.toUpperCase() == 'MONTHLY'
                ) {
                    this.schedules[i].period = 'Once a Month - Day ';
                    this.schedules[i].dayOfWeek = this.schedules[i].day;
                }

                this.schedules[i].dayOfWeek =
                    this.schedules[i].period +
                    //' - ' +
                    this.schedules[i].dayOfWeek;
            }

            this.dataSourceSchedule = new MatTableDataSource(this.schedules);
        });
    }
    gpo: GPO[] = [
        { value: 'ION', viewValue: 'ION' },
        { value: 'IPN', viewValue: 'IPN' },
        { value: 'INN', viewValue: 'INN' },
        { value: 'SAN', viewValue: 'SAN' },
    ];

    reportperiod: ReportPeriod[] = [
        { value: 'Weekly', viewValue: 'Weekly' },
        { value: 'Monthly', viewValue: 'Monthly' },
        { value: 'Quarterly', viewValue: 'Quarterly' },
        { value: 'QTD', viewValue: 'QTD' },
    ];

    reporttype: ReportType[] = [
        { value: 'MS', viewValue: 'MS' },
        { value: 'Sales', viewValue: 'Sales' },
        { value: 'Rebate', viewValue: 'Rebate' },
        { value: 'Admin Fee', viewValue: 'Admin Fee' },
    ];

    operator: Operator[] = [
        { value: '=', viewValue: '=' },
        { value: '<=', viewValue: '<=' },
        { value: '>=', viewValue: '>=' },
        { value: '<>', viewValue: '<>' },
        { value: 'IN', viewValue: 'IN' },
        { value: 'NOT IN', viewValue: 'NOT IN' },
        { value: 'AND/OR', viewValue: 'AND/OR' },
    ];

    edit() {
        this.viewMode = false;
        this.enableDisableControls();
    }

    //enable the controls on Edit
    enableDisableControls() {
        if (this.viewMode) {
            this.manufacturerFormControl.disable();
            this.reportperiodFormControl.disable();
            this.reporttypeFormControl.disable();
            this.gpoFormControl.disable();
            this.viewFormControl.disable();
            this.appendToNameFormControl.disable();

            this.productNameFormControl.disable();
            this.valueFormControl.disable();
            this.operatorFormControl.disable();
            this.columnselectionFormControl.disable();
            this.emailtemplateFormControl.disable();
        } else {
            this.manufacturerFormControl.enable();
            this.reportperiodFormControl.enable();
            this.reporttypeFormControl.enable();
            this.gpoFormControl.enable();
            this.viewFormControl.enable();
            this.appendToNameFormControl.enable();

            this.productNameFormControl.enable();
            this.valueFormControl.enable();
            this.operatorFormControl.enable();
            this.columnselectionFormControl.enable();
            this.emailtemplateFormControl.enable();
        }
    }

    cancel() {
        this.router.navigateByUrl('/reportmanagement');
    }

    getLoginUserId() {
        return clientSession.linkUser?.id || '';
    }
    //Save the data on submit
    save() {
        this.submitted = true;
        if (this.viewMode) {
            this.openSnackBar('Report saved successfully');
            this.router.navigateByUrl('/reportmanagement');
        }
        if (this.selectionSchedule.selected.length === 0) {
            return;
        } else if (this.selection.selected.length === 0) {
            return;
        }

        if (this.formGroup.invalid) {
            for (
                var count = 0;
                count < this.typesOfSelectedReportViewColumns.length - 1;
                count++
            ) {
                this.typesOfSelectedReportViewColumns[count].sortOrder =
                    count + 1;
            }
            this.SaveSelectedReport.reportColumns = this.columnsToString(
                this.typesOfSelectedReportViewColumns
            );
            return;
        } else {
            this.SaveSelectedReport.manufacturerId =
                this.manufacturerFormControl.value.manufacturerId;
            this.SaveSelectedReport.manufacturerName =
                this.manufacturerFormControl.value.manufacturerName;
            this.SaveSelectedReport.productId =
                this.productNameFormControl.value.productId;
            this.SaveSelectedReport.productName =
                this.productNameFormControl.value.productName;
            this.SaveSelectedReport.reportPeriod =
                this.reportperiodFormControl.value;
            this.SaveSelectedReport.reportType =
                this.reporttypeFormControl.value;
            this.SaveSelectedReport.gpo = this.gpoFormControl.value;
            this.SaveSelectedReport.viewId = this.viewFormControl.value;

            this.SaveSelectedReport.enabled = true;

            this.SaveSelectedReport.appendToName =
                this.appendToNameFormControl.value;

            this.SaveSelectedReport.updateUserId = this.getLoginUserId();
            this.SaveSelectedReport.updateDate = new Date();

            //ReportEmailGroup

            if (this.selection.selected.length > 0) {
                this.SaveSelectedReport.reportEmailGroups =
                    new Array<ReportEmailGroup>();

                for (
                    var count = 0;
                    count < this.selection.selected.length;
                    count++
                ) {
                    var SaveEmailGroup: ReportEmailGroup =
                        {} as ReportEmailGroup;
                    SaveEmailGroup.emailGroupId =
                        this.selection.selected[count].id;
                    SaveEmailGroup.updateDate = new Date();
                    SaveEmailGroup.updateUserId = this.getLoginUserId(); //this.SaveSelectedReport.reportEmailGroups.

                    this.SaveSelectedReport.reportEmailGroups.push(
                        SaveEmailGroup
                    );
                }
            }

            //Report Schedule
            if (this.selectionSchedule.selected.length > 0) {
                this.SaveSelectedReport.reportSchedules =
                    new Array<ReportSchedule>();

                for (
                    var count = 0;
                    count < this.selectionSchedule.selected.length;
                    count++
                ) {
                    var SaveSchedule: ReportSchedule = {} as ReportSchedule;
                    SaveSchedule.scheduleId =
                        this.selectionSchedule.selected[count].id;
                    SaveSchedule.updateDate = new Date();
                    SaveSchedule.updateUserId = this.getLoginUserId(); //this.SaveSelectedReport.reportEmailGroups.

                    this.SaveSelectedReport.reportSchedules.push(SaveSchedule);
                }
            }

            //Report EmailTemplate
            this.saveSelectedRptEmailtemplateObj.emailTemplateId =
                this.emailtemplateFormControl.value;
            this.saveSelectedRptEmailtemplateObj.updateDate = new Date();
            this.saveSelectedRptEmailtemplateObj.updateUserId =
                this.getLoginUserId();

            this.SaveSelectedReport.reportEmailTemplate =
                this.saveSelectedRptEmailtemplateObj;

            //Report Columns
            for (
                var count = 0;
                count < this.typesOfSelectedReportViewColumns.length;
                count++
            ) {
                this.typesOfSelectedReportViewColumns[count].sortOrder =
                    count + 1;
            }
            this.SaveSelectedReport.reportColumns = this.columnsToString(
                this.typesOfSelectedReportViewColumns
            );

            //Report Parameters
            this.SaveSelectedReport.reportParameters = this.reportParameterlist;

            if (this.isEdit == true) {
                this.SaveSelectedReport.id = this.selectedReport?.id;
                this.reportservice
                    .EditReport(this.SaveSelectedReport)
                    .pipe(catchError(this.handleError))
                    .subscribe(result => {
                        if (
                            result != undefined &&
                            result.status === 'success'
                        ) {
                            this.openSnackBar('Report updated successfully');
                            this.router.navigateByUrl('/reportmanagement');
                        } else if (
                            result != undefined &&
                            result.status === 'Error: MaxLength'
                        ) {
                            this.openSnackBar(
                                'Error : Report Name Length is greater than 255 characters.'
                            );
                        } else if (
                            result != undefined &&
                            result.status === 'Error: NotUnique'
                        ) {
                            this.openSnackBar(
                                'Error : Report Name is not unique.'
                            );
                        } else {
                            this.openSnackBar(
                                'Error : Error in saving the Report.'
                            );
                        }
                    });
            } else {
                this.reportservice
                    .SaveReport(this.SaveSelectedReport)
                    .pipe(catchError(this.handleError))
                    .subscribe(result => {
                        if (
                            result != undefined &&
                            result.status === 'Error: MaxLength'
                        ) {
                            this.openSnackBar(
                                'Error : Report Name Length is greater than 255 characters.'
                            );
                        } else if (
                            result != undefined &&
                            result.status === 'Error: NotUnique'
                        ) {
                            this.openSnackBar(
                                'Error : Report Name is not unique.'
                            );
                        } else if (result != undefined) {
                            this.openSnackBar('Report saved successfully');
                            this.router.navigateByUrl('/reportmanagement');
                            //this.router.navigate(['/reportmanagement'], { queryParams: { filter: queryFilterValues } });
                        } else {
                            this.openSnackBar(
                                'Error : Error in saving the Report.'
                            );
                        }
                    });
            }
        }
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `Backend returned code ${error.status}, body was: `,
                error.error
            );
        }
        // Return an observable with a user-facing error message.
        return throwError(
            () => new Error('Something bad happened; please try again later.')
        );
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    //changeSchedule(event: any) {

    //    if (event.isUserInput) {
    //        if (event.source.selected) {
    //            this.selectedschedules.push(event.source.viewValue);
    //        } else {
    //            if (
    //                this.selectedschedules.indexOf(event.source.viewValue) >= 0
    //            ) {
    //                this.selectedschedules.forEach((element, index) => {
    //                    if (element == event.source.viewValue)
    //                        this.selectedschedules.splice(index, 1);
    //                });
    //            }
    //        }
    //    }
    //}

    //Handles the checkbox check/uncheck event and Move the selected Email groups to the top
    enableEmailgroup(row: any, event: any) {
        if (event.target.checked) {
            this.selectedEmailgroup.push(row);
            //
            const selctedEmailGroup = this.emailGrouplist?.filter(
                r => r.id === row.id
            );
            this.emailGrouplist = this.emailGrouplist?.filter(
                r => r.id !== row.id
            );

            if (this.emailGrouplist != null && selctedEmailGroup != null) {
                this.emailGrouplist = selctedEmailGroup?.concat(
                    this.emailGrouplist
                );
            }
            this.dataSource = new MatTableDataSource(this.emailGrouplist);
            //
        } else {
            if (this.selectedEmailgroup.indexOf(row) >= 0) {
                this.selectedEmailgroup.forEach((element, index) => {
                    if (element == row)
                        this.selectedEmailgroup.splice(index, 1);
                });
            }
            //
            const selctedEmailGroup = this.emailGrouplist?.filter(
                r => r.id === row.id
            );
            this.emailGrouplist = this.emailGrouplist?.filter(
                r => r.id !== row.id
            );

            if (this.emailGrouplist != null && selctedEmailGroup != null) {
                //this.emailGrouplist = this.emailGrouplist?.concat(
                //    selctedEmailGroup
                //);
                this.emailGrouplist = this.emailGrouplist
                    ?.slice(0, this.selectedEmailgroup.length)
                    .concat(selctedEmailGroup)
                    .concat(
                        this.emailGrouplist?.slice(
                            this.selectedEmailgroup.length,
                            this.emailGrouplist.length
                        )
                    );
            }
            this.dataSource = new MatTableDataSource(this.emailGrouplist);
            //
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach(row => this.selection.select(row));
    }

    //Handles the checkbox check/uncheck event of schedules and Move the selected schedules to the top
    enableSchedule(row: any, event: any) {
        if (event.target.checked) {
            this.selectedschedules.push(row);
            const selctedSchedule = this.schedules?.filter(
                r => r.id === row.id
            );
            this.schedules = this.schedules?.filter(r => r.id !== row.id);

            if (this.schedules != null && selctedSchedule != null) {
                this.schedules = selctedSchedule?.concat(this.schedules);
            }
            this.dataSourceSchedule = new MatTableDataSource(this.schedules);
        } else {
            if (this.selectedschedules.indexOf(row) >= 0) {
                this.selectedschedules.forEach((element, index) => {
                    if (element == row) this.selectedschedules.splice(index, 1);
                });
            }
            //
            const selctedSchedule = this.schedules?.filter(
                r => r.id === row.id
            );
            this.schedules = this.schedules?.filter(r => r.id !== row.id);

            if (this.schedules != null && selctedSchedule != null) {
                //this.emailGrouplist = this.emailGrouplist?.concat(
                //    selctedEmailGroup
                //);
                this.schedules = this.schedules
                    ?.slice(0, this.selectedschedules.length)
                    .concat(selctedSchedule)
                    .concat(
                        this.schedules?.slice(
                            this.selectedschedules.length,
                            this.schedules.length
                        )
                    );
            }
            this.dataSourceSchedule = new MatTableDataSource(this.schedules);
            //
        }
    }

    isAllSelectedSchedule() {
        const numSelected = this.selectionSchedule.selected.length;
        const numRows = this.dataSourceSchedule.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggleSchedule() {
        this.isAllSelectedSchedule()
            ? this.selectionSchedule.clear()
            : this.dataSourceSchedule.data.forEach(row =>
                  this.selectionSchedule.select(row)
              );
    }

    //Adds the selected columns to the selected list
    // add auto alias to old columns
    AddColumns(data: any) {
        
        for (var i = 0; i < data.length; i++) {
            let foundAlias = this.reportcolumnlegacyalias.find((element) => element.newColumn === data[i].value);
            this.objReportColumn = {
                columnName: data[i].value,
                alias: foundAlias ? foundAlias.oldColumn : '',
                sortOrder: 0,
                updateDate: new Date(),
                updateUserId: this.getLoginUserId(),
            };
            this.typesOfSelectedReportViewColumns.push(this.objReportColumn);
            if (this.typesOfReportViewColumns.indexOf(data[i].value) > -1) {
                this.typesOfReportViewColumns.splice(
                    this.typesOfReportViewColumns.indexOf(data[i].value),
                    1
                );
            }
        }
    }
    //Removes the selected columns from the selected list
    RemoveColumns(data: any) {
        for (var i = 0; i < data.length; i++) {
            this.typesOfReportViewColumns.push(data[i].value);
            if (
                this.typesOfSelectedReportViewColumns.some(
                    x => x.columnName === data[i].value
                )
            ) {
                this.typesOfSelectedReportViewColumns.splice(
                    this.typesOfSelectedReportViewColumns.findIndex(
                        x => x.columnName === data[i].value
                    ),
                    1
                );
            }
        }
    }
    //Moves the column one position upwards
    SortColumnsUp(data: any) {
        var temp;
        var Swap;

        for (var i = 0; i < data.length; i++) {
            if (
                this.typesOfSelectedReportViewColumns.findIndex(
                    x => x.columnName === data[i].value
                ) > -1
            ) {
                var position = this.typesOfSelectedReportViewColumns.findIndex(
                    x => x.columnName === data[i].value
                );

                if (position != 0) {
                    Swap = position - 1;
                    temp = this.typesOfSelectedReportViewColumns[position];
                    this.typesOfSelectedReportViewColumns[position] =
                        this.typesOfSelectedReportViewColumns[Swap];
                    this.typesOfSelectedReportViewColumns[Swap] = temp;
                }
            }
        }
    }
    //Moves the column one position downwards
    SortColumnsDown(data: any) {
        var temp;
        var Swap;

        for (var i = data.length - 1; i >= 0; i--) {
            if (
                this.typesOfSelectedReportViewColumns.findIndex(
                    x => x.columnName === data[i].value
                ) > -1
            ) {
                var position = this.typesOfSelectedReportViewColumns.findIndex(
                    x => x.columnName === data[i].value
                );

                if (
                    position !=
                    this.typesOfSelectedReportViewColumns.length - 1
                ) {
                    Swap = position + 1;
                    temp = this.typesOfSelectedReportViewColumns[position];
                    this.typesOfSelectedReportViewColumns[position] =
                        this.typesOfSelectedReportViewColumns[Swap];
                    this.typesOfSelectedReportViewColumns[Swap] = temp;
                }
            }
        }
    }

    LoadViewByID(viewID: any) {
        this.reportservice.loadViewByID(viewID).subscribe(result => {
            this.selectedView = result;
            if (this.selectedView != undefined) {
                this.typesOfReportViewColumns =
                    this.selectedView.viewColumns.split(',');
                this.reportViewColumns = this.selectedView.viewColumns
                    .split(',')
                    .sort();
                this.filteredOptions =
                    this.columnselectionFormControl.valueChanges.pipe(
                        startWith(''),
                        map(value => this.filterReportViewColumns(value || ''))
                    );
            }
        });
    }

    LoadReportByID(id: string) {
        this.reportservice.loadReportByID(id).subscribe(result => {
            this.selectedReport = result;

            this.reportParameterlist = this.selectedReport.reportParameters;
            this.dataSourceReportParameters = new MatTableDataSource(
                this.reportParameterlist
            );
            if (
                this.selectedReport != undefined &&
                this.selectedReport.reportSchedules != undefined
            ) {
                for (var element of this.selectedReport.reportSchedules) {
                    var target2 = this.schedules.find(
                        temp => temp.id == element.scheduleId
                    );
                    if (target2 != undefined) {
                        // this.selectedschedules.push(target2);
                        this.selectionSchedule.select(target2);
                        //this.selectionSchedule.selected.
                        //
                        const selctedSchedule = this.schedules?.filter(
                            r => r.id === element.scheduleId
                        );
                        this.schedules = this.schedules?.filter(
                            r => r.id !== element.scheduleId
                        );

                        if (this.schedules != null && selctedSchedule != null) {
                            this.schedules = selctedSchedule?.concat(
                                this.schedules
                            );
                        }
                        //
                    }
                }
                this.dataSourceSchedule = new MatTableDataSource(
                    this.schedules
                );
            }

            //set value for emailGroup
            if (
                this.selectedReport != undefined &&
                this.selectedReport.reportEmailGroups != undefined
            ) {
                for (var elementEmailGroup of this.selectedReport
                    .reportEmailGroups) {
                    var target = this.emailGrouplist.find(
                        temp => temp.id == elementEmailGroup.emailGroupId
                    );
                    if (target != undefined) {
                        this.selection.select(target);
                        //
                        const selctedEmailGroup = this.emailGrouplist?.filter(
                            r => r.id === elementEmailGroup.emailGroupId
                        );
                        this.emailGrouplist = this.emailGrouplist?.filter(
                            r => r.id !== elementEmailGroup.emailGroupId
                        );

                        if (
                            this.emailGrouplist != null &&
                            selctedEmailGroup != null
                        ) {
                            this.emailGrouplist = selctedEmailGroup?.concat(
                                this.emailGrouplist
                            );
                        }
                        //
                    }
                }
                this.dataSource = new MatTableDataSource(this.emailGrouplist);
            }

            //set value for email template
            if (
                this.selectedReport != undefined &&
                this.selectedReport.reportEmailTemplate != undefined
            ) {
                this.emailtemplateFormControl.setValue(
                    this.selectedReport.reportEmailTemplate.emailTemplateId
                );
            }

            if (
                this.selectedReport != undefined &&
                this.selectedReport.reportColumns != undefined
            ) {
                var segReportColumns: string[] =
                    this.selectedReport.reportColumns.split(',');
                for (let i = 0; i < segReportColumns.length; i++) {
                    let dividedCol: string[] =
                        segReportColumns[i].split(' as ');
                    let tempColumn: ReportColumn;

                    tempColumn = {
                        columnName: dividedCol[0],
                        alias: dividedCol.length == 1 ? '' : dividedCol[1],
                        sortOrder: 0,
                        updateDate: new Date(),
                        updateUserId: '',
                    };

                    this.typesOfSelectedReportViewColumns.push(tempColumn);
                }
                this.SyncColumns();
            }
        });
    }
    //Sync the column list and the selected column list during Edit
    SyncColumns() {
        for (var i = 0; i < this.typesOfSelectedReportViewColumns.length; i++) {
            if (
                this.typesOfReportViewColumns.indexOf(
                    this.typesOfSelectedReportViewColumns[i].columnName
                ) > -1
            ) {
                this.typesOfReportViewColumns.splice(
                    this.typesOfReportViewColumns.indexOf(
                        this.typesOfSelectedReportViewColumns[i].columnName
                    ),
                    1
                );
            }
        }
    }

    changeViewColumns(data: any) {
        this.LoadViewByID(data.value);
        this.typesOfSelectedReportViewColumns = [];
    }

    //Add a new Report parameter
    AddReportParameters() {
        if (
            (this.columnselectionFormControl.value === '' ||
                (this.valueFormControl.value != undefined &&
                    this.valueFormControl.value.trim().length === 0) ||
                this.operatorFormControl.value === '') &&
            this.operatorFormControl.value !== 'AND/OR'
        ) {
            this.openSnackBar(
                'Please select a column, operator and value to add a parameters.'
            );
            return;
        } else if (
            this.valueFormControl.value === '' &&
            this.operatorFormControl.value === 'AND/OR'
        ) {
            this.openSnackBar(
                'Please select an operator and value to add a parameters.'
            );
            return;
        } else if (
            this.reportViewColumns.indexOf(
                this.columnselectionFormControl.value
            ) === -1 &&
            this.operatorFormControl.value !== 'AND/OR'
        ) {
            this.openSnackBar(
                'Please select a column, operator and value to add a parameters.'
            );
            return;
        } else if (
            this.reportParameterlist.findIndex(
                x =>
                    x.columnName === this.columnselectionFormControl.value &&
                    x.parameterValue === this.valueFormControl.value?.trim() &&
                    x.operator === this.operatorFormControl.value
            ) > -1
        ) {
            this.openSnackBar('Duplicate Report Parameters');
            return;
        }
        this.tmpobjReportParameter = {
            columnName: this.columnselectionFormControl.value,
            parameterValue: this.valueFormControl.value?.trim() || '',
            operator: this.operatorFormControl.value,
            updateDate: new Date(),
            updateUserId: this.getLoginUserId(),
            active: true,
        };
        this.columnselectionFormControl.setValue('');
        this.valueFormControl.setValue('');
        this.operatorFormControl.setValue('');
        this.reportParameterlist.push(this.tmpobjReportParameter);
        this.dataSourceReportParameters = new MatTableDataSource(
            this.reportParameterlist
        );
    }

    //Delete a report parameter
    deleteReportParameter(data: any) {
        if (!this.viewMode) {
            this.reportParameterlist.splice(
                this.reportParameterlist.findIndex(
                    x =>
                        x.columnName === data.columnName &&
                        x.parameterValue === data.parameterValue &&
                        x.operator === data.operator
                ),
                1
            );
            this.dataSourceReportParameters = new MatTableDataSource(
                this.reportParameterlist
            );
        }
    }

    //Confirm Dialog for deleting Report parameter
    deleteReportParameterDialog(data: any) {
        const userDialogConfig = new MatDialogConfig();
        const rowid = this.reportParameterlist.findIndex(
            x =>
                x.columnName === data.columnName &&
                x.parameterValue === data.parameterValue &&
                x.operator === data.operator
        );
        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = {
            data: {
                message: 'Are you sure want to delete?',
                rowid: rowid,
                source: 'ReportWizard',
                buttonText: {
                    ok: 'Delete',
                    cancel: 'No',
                },
            },
        };

        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.deleteReportParameter(data);
                }
            });
    }

    columnsToString(colms: Array<ReportColumn>) {
        let endString: string = '';
        colms.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));

        for (let i = 0; i < colms.length; i++) {
            //actually make changes for alias here
            let foundAlias = this.reportcolumnlegacyalias.find((element) => element.newColumn === colms[i].alias);
            let forcedAlias = foundAlias !== undefined ? foundAlias.oldColumn : '';
            endString += colms[i].columnName.concat(
                colms[i].alias == ''
                    ? ' as '.concat(forcedAlias).concat(',')
                    : ' as '.concat(colms[i].alias).concat(',')
            );
        }
        return endString.slice(0, -1);
    }

    openSnackBar(message: string) {
        this._snackBar.open(message, 'Ok', {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            duration: 6000,
            panelClass: ['green-snackbar'],
        });
    }

    isAddOrEdit() {
        return this.isEdit ? 'Edit' : 'Create';
    }

    ////Restrict special characters in report Append to Report Name
    //restrict_special_char(event: any) {
    //    var k;
    //    k = event.charCode;
    //    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 40 || k == 41 || k == 91 ||
    //        k == 93 || k == 45 || k == 95 || (k >= 48 && k <= 57));

    //}
}
