import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Note } from '../../../model/Note';
import { QuarterCode } from '../../../model/QuarterCode';
import { SystemConfiguration } from '../../../model/SystemConfiguration';
import { SystemStatus } from '../../../model/SystemStatus';
import { ScorecardService } from '../../../services/scorecard.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { NotesCopydialogComponent } from '../notes-copydialog/notes-copydialog.component';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { Agreement } from '../../../model/Agreement';

@Component({
    selector: 'app-notes-list',
    templateUrl: './notes-list.component.html',
    styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnInit {
    notes: Array<Note> = [];
    quarterCode: Array<QuarterCode> = [];
    agreementList: Array<Agreement> | undefined = [];
    @ViewChild(MatSort) sort!: MatSort;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    dataSource: MatTableDataSource<Note>;
    filterValue: string;
    displayedColumns: string[] = [
        'subject',
        'noteText',
        'updateDate',
        'active',
        'actionsColumn',
    ];
    public quarterCodeFormControl: FormControl;
    public systemConfiguration?: SystemConfiguration[];
    selectedQuarterCode: QuarterCode = {} as QuarterCode;
    cacheRefreshDate: Date | null;

    constructor(
        private dialog: MatDialog,
        private http: HttpClient,
        private service: ScorecardService
    ) {
        this.filterValue = '';
        this.dataSource = new MatTableDataSource(this.notes);
        this.dataSource.filterPredicate = (data: Note, filterValue: string) =>
            data.subject.trim().toLowerCase().indexOf(filterValue) !== -1 ||
            data.noteAgreements.some(
                agr =>
                    agr.agreementName.toLowerCase().indexOf(filterValue) !== -1
            ) ||
            data.noteAgreements.some(
                agr => agr.agreementId.toLowerCase().indexOf(filterValue) !== -1
            );

        this.quarterCodeFormControl = new FormControl('');
    }

    ngOnInit(): void {
        this.loadAgreementList();
        this.loadQuarterCode();
        this.getCacheRefreshDate();
    }

    loadQuarterCode() {
        this.service.loadQuarterCode().subscribe(result => {
            this.quarterCode = result.sort((a, b) =>
                a.sortPosition < b.sortPosition ? -1 : 1
            );
            const currentquarter = result.find(
                obj => obj.currentQuarter === true
            );
            if (currentquarter != null) {
                this.quarterCodeFormControl.setValue(currentquarter.quarter);
                this.selectedQuarterCode = currentquarter;
            }
            this.loadNoteList();
        });
    }
    //Opens the Dialog on click of New Note
    newnote() {
        const userDialogConfig = new MatDialogConfig();
        //var note: Note = {} as Note;

        //note.quarter = this.quarterCodeFormControl.value;
        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        //userDialogConfig.data = this.selectedQuarterCode;// note;
        userDialogConfig.data = {
            SelectedQuarter: this.selectedQuarterCode,
            AgreementList: this.agreementList,
        };
        userDialogConfig.width = '90%';
        userDialogConfig.height = '90%';
        this.dialog
            .open(NotesDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.loadNoteList();
                }
            });
    }
    //Opens the Dialog on click of Copy Note
    copynote() {
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = true;
        userDialogConfig.autoFocus = true;
        //userDialogConfig.data = this.selection.selected; // this.moveitemlist;
        userDialogConfig.width = '50%';
        userDialogConfig.height = '45%';
        this.dialog
            .open(NotesCopydialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                this.loadNoteList();
            });
    }
    //Excel Downloads of the notes table
    downloadNotes() {
        this.service
            .generateExcelForNotesExport(this.quarterCodeFormControl.value)
            .subscribe(data => {
                var file = new Blob([data], {
                    type: 'application/vnd.ms-excel',
                });
                var fileURL = URL.createObjectURL(file);
                let downloadLink = document.createElement('a');
                document.body.appendChild(downloadLink);
                downloadLink.style.display = 'none';
                downloadLink.href = fileURL;
                downloadLink.target = '_blank';
                downloadLink.download = 'Notes.xlsx';
                downloadLink.click();
                downloadLink.remove();
            });
    }
    applyFilter(filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        this.filterValue = filterValue;
        this.dataSource.filter = filterValue;
    }

    getNote(rowid: any) {
        const noteDialogConfig = new MatDialogConfig();

        noteDialogConfig.disableClose = true;
        noteDialogConfig.autoFocus = true;
        //noteDialogConfig.data = rowid;
        noteDialogConfig.data = {
            SelectedNote: rowid,
            SelectedQuarter: this.selectedQuarterCode,
            AgreementList: this.agreementList,
        };
        noteDialogConfig.width = '90%';
        noteDialogConfig.height = '90%';

        this.dialog
            .open(NotesDialogComponent, noteDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.loadNoteList();
                }
            });
    }

    loadNoteList() {
        this.service
            .loadnotelistbyquarter(this.quarterCodeFormControl.value)
            .subscribe(result => {
                this.notes = result;
                this.dataSource = new MatTableDataSource(this.notes);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
                if (this.filterValue != '') {
                    this.applyFilter(this.filterValue);
                }
                this.dataSource.filterPredicate = (
                    data: Note,
                    filterValue: string
                ) =>
                    data.subject.trim().toLowerCase().indexOf(filterValue) !==
                        -1 ||
                    data.noteAgreements.some(
                        agr =>
                            agr.agreementName
                                .toLowerCase()
                                .indexOf(filterValue) !== -1
                    ) ||
                    data.noteAgreements.some(
                        agr =>
                            agr.agreementId
                                .toLowerCase()
                                .indexOf(filterValue) !== -1
                    );
            });
    }

    async loadAgreementList() {
        this.agreementList = await this.http
            .get<Array<Agreement>>('/api/limitedagreements')
            .toPromise();
        //console.log( this.agreementList?.length);
    }
    //Handles the Quarter dropdown change event
    changeNotesList(quarter: any) {
        const currentquarter = this.quarterCode.find(
            obj => obj.quarter === quarter
        );
        if (currentquarter != undefined) {
            this.selectedQuarterCode = currentquarter;
        }
        this.loadNoteList();
    }
    //Delete the notes on click of Delete Icon
    deleteNote(rowid: string) {
        const userDialogConfig = new MatDialogConfig();

        userDialogConfig.disableClose = false;
        userDialogConfig.autoFocus = true;
        userDialogConfig.data = {
            data: {
                message: 'Are you sure want to delete?',
                rowid: rowid,
                source: 'noteslist',
                buttonText: {
                    ok: 'Delete',
                    cancel: 'Cancel',
                },
            },
        };

        this.dialog
            .open(ConfirmDialogComponent, userDialogConfig)
            .afterClosed()
            .subscribe(saved => {
                if (saved) {
                    this.loadNoteList();
                }
            });
    }

    getCacheRefreshDate() {
        this.http.get<SystemStatus>('/api/getCacheRefreshDate').subscribe(
            result => {
                if (result.value == "") {
                    this.cacheRefreshDate = null;
                }
                else {
                    this.cacheRefreshDate = new Date(result.value);
                }

            }
        );
    }
}
